<template>
  <div>
    <el-form-item label="推广网址" prop="webLinkList">
      <span slot="label">
        <span>推广网址</span>
        <web-tips></web-tips>
      </span>
      <div
        v-for="(link, index) in webLinkList"
        :key="index"
        style="margin-bottom: 10px"
      >
        <el-row>
          <el-col :span="20">
            <el-autocomplete
              :fetch-suggestions="querySearch"
              placeholder="请输入推广网址"
              style="width: 86%; margin-right: 10px"
              @change="onInputWebLink"
              v-model="link.url"
              @select="selectwebLink"
              @focus="linkIndex = index"
              :class="[webLinkList.filter(v=>v.url == webLinkList[index].url).length>=2?'redBorder':'']"
            >
              <template slot-scope="item">
                <div>{{ item.item }}</div>
              </template>
            </el-autocomplete>
            <el-button
              type="text"
              icon="el-icon-aim"
              @click="openLink(link.url)"
              :disabled="!link.url"
              >检查</el-button
            >
            <p v-if="link.checkStatus"
              :class="['tipsColor',link.checkStatus.level]"
              v-loading="link.checkLoading"
            >
              {{ link.checkStatus.message }}
            </p>
          </el-col>
          <el-col :span="4">
            <el-button
              icon="el-icon-minus"
              type="text"
              @click="deleItem('webLinkList', index)"
              v-if="webLinkList.length > 1"
            ></el-button>
            <el-button
              icon="el-icon-plus"
              type="text"
              @click="addItem('webLinkList')"
              v-if="index == webLinkList.length - 1 && webLinkList.length < 5"
            ></el-button>
          </el-col>
        </el-row>
      </div>
    </el-form-item>
  </div>
</template>

<script>
import webTips from "../webTips";
import { websiteUrlCheck } from "@/api/creatAd.js";
export default {
  props: {
    linkHistory: {
      type: Array,
      default: () => [],
    },
    initData: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    webTips,
  },
  data() {
    return {
      webLinkList: [],
    };
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.linkHistory;
      var results = queryString
        ? restaurants.filter((n) => {
            return n.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
          })
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    // 输入网址校验
    selectwebLink(v) {
      // this.checkLoading = true;
      console.log(v);
      this.onInputWebLink(v, this.linkIndex);
      this.$set(this.webLinkList[this.linkIndex], "url", v);
      this.webLinkList[this.linkIndex].url = v;
      this.emitWebLink();
    },
    onInputWebLink(v, index) {
      console.log(v);
      if (!v) return false;
      this.webLinkList[this.linkIndex].checkLoading = true;
      websiteUrlCheck({
        accountId: this.$store.getters.accountId,
        siteUrl: v,
      }).then((res) => {
        // console.log(res);
        this.webLinkList[this.linkIndex].checkLoading = false;
        if (res.code == 0) {
          this.webLinkList[this.linkIndex].checkStatus = res.data;
          this.emitWebLink();
        }
      });
    },
    // 点击检查网址
    openLink(link){
        window.open(link)
    },
    // 添加
    addItem(type) {
      if (type == "webLinkList") {
        this[type].push({
          url: "",
          checkStatus: {
            message:
              "请输入完整的网址，需包含https或者http。例如：https://www.giikin.com/页面路径…",
            level: "error",
          },
          checkLoading: false,
        });
      } else {
        this[type].push("");
      }
      this.emitWebLink();
    },
    // 删除
    deleItem(type, index) {
      this[type].splice(index, 1);
      this.emitWebLink();
    },
    emitWebLink() {
      console.log(this.webLinkList);
      this.$emit("input", this.webLinkList.map(v=>v.url));
    },
  },
  watch: {
    value: {
      handler() {
          console.log(this.value);
        let list = [];
        this.value.map((v) => {
          list.push({
            url: v,
            checkStatus: {
              message:"",
              level: "ok",
            },
            checkLoading: false,
          });
        });
        this.webLinkList = JSON.parse(JSON.stringify(list));
        console.log(this.webLinkList);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.el-autocomplete{
  &.redBorder{
    .el-input__inner,.el-textarea__inner{
      border:1px solid #ff0000 !important;
    }
    
  }
}
</style>