<template>
  <div>
    <el-dialog
      :visible="uploadThumbShow"
      width="620px"
      @close="cancle"
      @opened="opened"
      append-to-body
    >
      <el-button size="small" @click="imgListShow = true"
        >从创意云选择</el-button
      >
      <el-upload
        class="upload-demo"
        action="#"
        :http-request="uploadVideoImg"
        :show-file-list="false"
        :before-upload="beforeUpload"
        style="display: inline-block; margin-left: 20px"
      >
        <el-button size="small" type="primary"
          >点击上传</el-button
        >
      </el-upload>
      <div class="adCon" style="margin-top: 10px">
        <div class="thumbList" v-if="thumbList">
          <div
            class="thumbWrap"
            v-for="(img, thumbIndex) in thumbList"
            :key="thumbIndex"
            @click="setVideoThumb(img.uri, thumbIndex)"
          >
            <el-image :src="img.uri" fit="contain"></el-image>
            <i class="el-icon-circle-check" v-if="img.isPreferred"></i>
          </div>

          <!-- <video controls :src="adsList[index].videoThumb"></video> -->
          <!-- <el-image :src="adsList[index].thumbUrl" :preview-src-list="[adsList[index].thumbUrl]"></el-image> -->
        </div>
        <div class="empty" v-else style="width: 100%">
          <span>
            <i class="iconfont icon-wushuju"></i>
            <p>暂无数据</p>
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle"
          >取 消</el-button
        >
        <el-button type="primary" @click="setThumb">确 定</el-button>
      </span>
    </el-dialog>
    <img-list
      :imgListShow="imgListShow"
      @close="close"
      @urlList="urlList"
      uploadTypes="img"
    ></img-list>
  </div>
</template>
<script>
import adsMethods from "../../adsMethods.js";
import imgList from "../imgList";
import {
  upload,
  uploadUrl,
  accountPages,
  pageInstagrams,
  pagePosts,
  historyInput,
  websiteUrlCheck,
  preview,
  accountPixel,
  uploadVideoThumb,
  mediaFileUploadFb,
  querySlideshowVideo,
} from "@/api/creatAd.js";
export default {
  mixins: [adsMethods],
  props: {
    uploadThumbShow: {
      type: Boolean,
      default: false,
    },
    sourceMsg: {
      type: Object,
      default: () => {
        return {
          url: "",
          source_id: null,
          thumbUrl: "",
          thumbList: [],
        };
      },
    },
  },
  components: {
    imgList,
  },
  data() {
    return {
      imgListShow: false,
      thumbList: JSON.parse(JSON.stringify(this.sourceMsg.thumbList)),
      formData: null,
    };
  },
  methods: {
    close(v) {
      this[v] = false;
    },
    opened() {
      this.thumbList = JSON.parse(JSON.stringify(this.sourceMsg.thumbList));
    },
    // 设置封面,选择
    setVideoThumb(url, index) {
      // console.log(index);
      if (index || index === 0) {
        this.thumbList.map((v, ind) => {
          if (index == ind) {
            this.$set(this.thumbList[ind], "isPreferred", true);
          } else {
            this.$set(this.thumbList[ind], "isPreferred", false);
          }
        });
      }
      let formData = new FormData();
      // console.log(url);
      formData.append("url", url);
      formData.append("id", this.sourceMsg.source_id);
      this.formData = formData;
    },
    // 从创意
    urlList(v) {
      let img = v[v.length - 1];
      let formData = new FormData();
      // console.log(url);
      formData.append("url", img.url);
      formData.append("id", img.id);
      this.ushiftThumb(img.url)
      this.formData = formData;
    },
    uploadVideoImg(file) {
        console.log(file);
      let formData = new FormData();
      formData.append("file", file.file);
      formData.append("id", this.sourceMsg.source_id);
      this.uploadImg(file).then((data) => {
        this.ushiftThumb(data.url);
      });
      this.formData = formData;
    },
    uploadThumb(formData) {
      this.$showLoading();
      uploadVideoThumb(formData).then((res) => {
        this.$hideLoading();
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "上传成功",
          });
          this.$emit('setThumb',res.data.thumbUrl)
          this.$emit('update:uploadThumbShow', false)
        }
      });
    },
    cancle(){
        this.$emit('update:uploadThumbShow', false)
    },
    ushiftThumb(res) {
      let obj = {
        isPreferred: true,
        uri: res,
      };
      this.thumbList.map((v) => (v.isPreferred = false));
      this.thumbList.unshift(obj);
    },
    // 确定封面
    setThumb() {
        this.uploadThumb(this.formData);
    },
  },
};
</script>
<style scoped lang="scss">
</style>